/* #region General */
:root {
  --bgColor: #f1f1f1;
  --pttGreenColor: #00a9ce;
  --pttYellowColor: #ffc72c;
  --pttLinkColor: #53565a;
  --primaryBgColor: #50a5ff;
  --darkPrimaryBgColor: #007cff;
  --secondaryBgColor: #858585;
  --darkSecondaryBgColor: #656565;
  --successBgColor: #30c781;
  --darkSuccessBgColor: #249b63;
  --dangerBgColor: #e34a4a;
  --darkDangerBgColor: #bd3c3c;
  --warningBgColor: #ffc72c;
  --darkWarningBgColor: #cda631;
  --warningFontColor: #5a4915;
  --infoBgColor: #41d2d9;
  --darkInfoBgColor: #33a7ad;
  --lightBgColor: #fff;
  --lightFontColor: #333;
  --lightDarkBgColor: #f1f1f1;
  --grayBgColor: #3d4b54;
  --darkGrayBgColor: #252e33;
  --linkBgColor: transparent;
  --linkFontColor: #888;
  --darkLinkBgColor: transparent;
  --modalInOutAnimation: visibility 0.2s, opacity 0.2s ease-in-out;
  --containerPadding: 1rem 10rem;
  --containerHeight: calc(100vh - 145px);
  --borderRadius: 2rem;
  --position: 0 0 0 0;
  --fontWeight: 600;
  --boxShadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);
  --transition: all 0.2s ease-in-out;
  --zIndex: 10;
}

@font-face {
  font-family: "OmnesRegular";
  src: url(../fonts/OmnesRegular.otf);
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-family: "OmnesRegular" !important;
  letter-spacing: 1px;
  list-style: none;
  outline: none;
}

.fas,.fa-solid{
  font-family: "Font Awesome 6 Free" !important;
}

.fab,.fa-brands{
  font-family: "Font Awesome 6 Brands" !important;
}

  

@media only screen and (max-width: 1280px) {
  * {
    font-size: 1rem !important;
  }
}

html {
  overflow-x: hidden;
  position: relative;
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: var(--bgColor);
  margin-top: 9rem;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 1.1rem;

  height: 5rem;
}

body::-webkit-scrollbar-track {
  border-radius: 1rem;
}

body::-webkit-scrollbar-thumb {
  background: var(--pttGreenColor);
  border-radius: 1rem;
  border: 3px solid transparent;
  background-clip: content-box;
}

.alotech-chat-widget {
  right: 1rem !important;
  bottom: 30px !important;
  z-index: 3 !important;
  transition: all 0.3s ease-in-out;
}

details {
  margin-bottom: 0.5rem;
}

details > summary {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  user-select: none;
  cursor: pointer;
  font-size: 1rem !important;
}
details > p {
  background-color: #666;
  padding: 1rem;
  width: 98%;
  margin: 0 auto;
  border-radius: 0 0 0.5rem 0.5rem;
  color: #fff !important;
}
details > div {
  background-color: #666;
  padding: 1rem;
  width: 98%;
  margin: 0 auto;
  border-radius: 0 0 0.5rem 0.5rem;
}
details > div > p {
  color: #fff !important;
}
details > div > ul {
  color: #fff !important;
}

/* Tablet */
@media only screen and (min-width: 790px) and (max-width: 1280px) {
  body {
    margin-top: 8rem;
  }
}

/* Mobile */
@media only screen and (max-width: 790px) {
  html {
    font-size: 85% !important;
  }

  body {
    margin-top: 9rem;
    margin-bottom: 4rem;
  }

  .alotech-chat-widget {
    bottom: 70px !important;
    z-index: 3 !important;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

button {
  cursor: pointer;
}

[class*="row"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  clear: both;
}

[class*="fade-in-out"] {
  visibility: visible !important;
  opacity: 1 !important;
}

[class*="overflow-hidden"] {
  overflow: hidden !important;
}

.position-relative {
  position: relative !important;
}

.align-items {
  align-items: center !important;
}

.ui-accordion-header::before {
  display: none !important;
}
.ui-accordion-header::after {
  display: none !important;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #858796;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e3e6f0;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
}

.table tbody + tbody {
  border-top: 2px solid #e3e6f0;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e3e6f0;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e3e6f0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(4n + 1) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #858796;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cdd8f6;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a3b6ee;
}

.table-hover .table-primary:hover {
  background-color: #b7c7f2;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b7c7f2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddde2;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c0c1c8;
}

.table-hover .table-secondary:hover {
  background-color: #cfcfd6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfcfd6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bff0de;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #89e2c2;
}

.table-hover .table-success:hover {
  background-color: #aaebd3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aaebd3;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c7ebf1;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #96dbe4;
}

.table-hover .table-info:hover {
  background-color: #b3e4ec;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e4ec;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fceec9;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fadf9b;
}

.table-hover .table-warning:hover {
  background-color: #fbe6b1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbe6b1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8ccc8;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f3a199;
}

.table-hover .table-danger:hover {
  background-color: #f5b7b1;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b7b1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfd;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d1d1d5;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a9aab1;
}

.table-hover .table-dark:hover {
  background-color: #c4c4c9;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c4c4c9;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #5a5c69;
  border-color: #6c6e7e;
}

.table .thead-light th {
  color: #6e707e;
  background-color: #eaecf4;
  border-color: #e3e6f0;
}

.table-dark {
  color: #fff;
  background-color: #5a5c69;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #6c6e7e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

figure img {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

/* #endregion */

/* #region Margin and Padding */
/* #region Margin */
.mt-1 {
  margin-top: 0.1rem;
}

.mt-2 {
  margin-top: 0.2rem;
}

.mt-3 {
  margin-top: 0.3rem;
}

.mt-4 {
  margin-top: 0.4rem;
}

.mt-5 {
  margin-top: 0.5rem;
}

.mt-6 {
  margin-top: 0.6rem;
}

.mt-7 {
  margin-top: 0.7rem;
}

.mt-8 {
  margin-top: 0.8rem;
}

.mt-9 {
  margin-top: 0.9rem;
}

.mt-10 {
  margin-top: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-right: 0.1rem;
}

.mr-2 {
  margin-right: 0.2rem;
}

.mr-3 {
  margin-right: 0.3rem;
}

.mr-4 {
  margin-right: 0.4rem;
}

.mr-5 {
  margin-right: 0.5rem;
}

.mr-6 {
  margin-right: 0.6rem;
}

.mr-7 {
  margin-right: 0.7rem;
}

.mr-8 {
  margin-right: 0.8rem;
}

.mr-9 {
  margin-right: 0.9rem;
}

.mr-10 {
  margin-right: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 0.1rem;
}

.ml-2 {
  margin-left: 0.2rem;
}

.ml-3 {
  margin-left: 0.3rem;
}

.ml-4 {
  margin-left: 0.4rem;
}

.ml-5 {
  margin-left: 0.5rem;
}

.ml-6 {
  margin-left: 0.6rem;
}

.ml-7 {
  margin-left: 0.7rem;
}

.ml-8 {
  margin-left: 0.8rem;
}

.ml-9 {
  margin-left: 0.9rem;
}

.ml-10 {
  margin-left: 1rem;
}

.mb-1 {
  margin-bottom: 0.1rem;
}

.mb-2 {
  margin-bottom: 0.2rem;
}

.mb-3 {
  margin-bottom: 0.3rem;
}

.mb-4 {
  margin-bottom: 0.4rem;
}

.mb-5 {
  margin-bottom: 0.5rem;
}

.mb-6 {
  margin-bottom: 0.6rem;
}

.mb-7 {
  margin-bottom: 0.7rem;
}

.mb-8 {
  margin-bottom: 0.8rem;
}

.mb-9 {
  margin-bottom: 0.9rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

/* #endregion */

/* #region Padding */
.pt-1 {
  padding-top: 0.1rem;
}

.pt-2 {
  padding-top: 0.2rem;
}

.pt-3 {
  padding-top: 0.3rem;
}

.pt-4 {
  padding-top: 0.4rem;
}

.pt-5 {
  padding-top: 0.5rem;
}

.pt-6 {
  padding-top: 0.6rem;
}

.pt-7 {
  padding-top: 0.7rem;
}

.pt-8 {
  padding-top: 0.8rem;
}

.pt-9 {
  padding-top: 0.9rem;
}

.pt-10 {
  padding-top: 1rem;
}

.pr-auto {
  padding-right: auto;
}

.pr-1 {
  padding-right: 0.1rem;
}

.pr-2 {
  padding-right: 0.2rem;
}

.pr-3 {
  padding-right: 0.3rem;
}

.pr-4 {
  padding-right: 0.4rem;
}

.pr-5 {
  padding-right: 0.5rem;
}

.pr-6 {
  padding-right: 0.6rem;
}

.pr-7 {
  padding-right: 0.7rem;
}

.pr-8 {
  padding-right: 0.8rem;
}

.pr-9 {
  padding-right: 0.9rem;
}

.pr-10 {
  padding-right: 1rem;
}

.pl-auto {
  padding-left: auto;
}

.pl-1 {
  padding-left: 0.1rem;
}

.pl-2 {
  padding-left: 0.2rem;
}

.pl-3 {
  padding-left: 0.3rem;
}

.pl-4 {
  padding-left: 0.4rem;
}

.pl-5 {
  padding-left: 0.5rem;
}

.pl-6 {
  padding-left: 0.6rem;
}

.pl-7 {
  padding-left: 0.7rem;
}

.pl-8 {
  padding-left: 0.8rem;
}

.pl-9 {
  padding-left: 0.9rem;
}

.pl-10 {
  padding-left: 1rem;
}

.pb-1 {
  padding-bottom: 0.1rem;
}

.pb-2 {
  padding-bottom: 0.2rem;
}

.pb-3 {
  padding-bottom: 0.3rem;
}

.pb-4 {
  padding-bottom: 0.4rem;
}

.pb-5 {
  padding-bottom: 0.5rem;
}

.pb-6 {
  padding-bottom: 0.6rem;
}

.pb-7 {
  padding-bottom: 0.7rem;
}

.pb-8 {
  padding-bottom: 0.8rem;
}

.pb-9 {
  padding-bottom: 0.9rem;
}

.pb-10 {
  padding-bottom: 1rem;
}

/* #endregion */
/* #endregion */

/* #region Float and Text Align*/
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

/* #endregion */

/* #region Buttons */
.btn-group > button {
  position: relative;
  margin-right: 0.1rem;
  padding: 0.6rem 1rem;
  border: none !important;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.btn-group > button:first-child {
  -webkit-border-radius: 2rem 0 0 2rem;
  -moz-border-radius: 2rem 0 0 2rem;
  border-radius: 2rem 0 0 2rem;
}

.btn-group > button:last-child {
  margin-right: 0;
  -webkit-border-radius: 0 2rem 2rem 0;
  -moz-border-radius: 0 2rem 2rem 0;
  border-radius: 0 2rem 2rem 0;
}

.btn-primary,
.btn-secondary,
.btn-success,
.btn-danger,
.btn-warning,
.btn-info,
.btn-light,
.btn-dark,
.btn-link {
  padding: 0.5rem 1rem;
  border: none;
  color: #fff;
  -webkit-box-shadow: var(--boxShadow);
  -moz-box-shadow: var(--boxShadow);
  -o-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
  outline: none;
  cursor: pointer;
}

.btn-primary {
  background-color: var(--primaryBgColor);
}

.btn-primary:hover {
  background-color: var(--darkPrimaryBgColor);
}

.btn-primary:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-secondary {
  background-color: var(--secondaryBgColor);
}

.btn-secondary:hover {
  background-color: var(--darkSecondaryBgColor);
}

.btn-secondary:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-success {
  background-color: var(--successBgColor);
}

.btn-success:hover {
  background-color: var(--darkSuccessBgColor);
}

.btn-success:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-danger {
  background-color: var(--dangerBgColor);
}

.btn-danger:hover {
  background-color: var(--darkDangerBgColor);
}

.btn-danger:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-warning {
  background-color: var(--warningBgColor);
}

.btn-warning:hover {
  background-color: var(--darkWarningBgColor);
}

.btn-warning:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-info {
  background-color: var(--infoBgColor);
}

.btn-info:hover {
  background-color: var(--darkInfoBgColor);
}

.btn-info:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-light {
  background-color: var(--lightBgColor);
  color: #666;
}

.btn-light:hover {
  background-color: var(--darkLinkBgColor);
}

.btn-light:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-dark {
  background-color: var(--grayBgColor);
}

.btn-dark:hover {
  background-color: var(--darkGrayBgColor);
}

.btn-dark:focus {
  box-shadow: var(--btnFocusBorderColor);
}

.btn-link {
  background-color: var(--linkBgColor);
  color: #666;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.btn-link:hover {
  background-color: var(--btnLinkHoverBgColor);
}

/* #endregion */

/* #region Input */
.input {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  -webkit-border-radius: var(--borderRadius);
  -moz-border-radius: var(--borderRadius);
  border-radius: var(--borderRadius);
  -webkit-box-shadow: var(--inputBorderColor);
  -moz-box-shadow: var(--inputBorderColor);
  -o-box-shadow: var(--inputBorderColor);
  box-shadow: var(--inputBorderColor);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  outline: none;
}

.input:focus {
  -webkit-box-shadow: var(--inputFocusBorderColor);
  -moz-box-shadow: var(--inputFocusBorderColor);
  -o-box-shadow: var(--inputFocusBorderColor);
  box-shadow: var(--inputFocusBorderColor);
}

::-webkit-input-placeholder {
  color: #999;
}

/* #endregion */

/* #region Field Title */
.field {
  position: relative;
}

.field-big-title {
  position: relative;
  margin-bottom: 2rem;
  color: #666;
  font-size: 1.6rem;
}

.field-big-title::after {
  position: absolute;
  top: 3rem;
  right: 0;
  left: 0;
  content: "";
  height: 5px;
  border-top: dashed 1px #e1e1e1;
  border-bottom: dashed 1px #e1e1e1;
}

.field-title {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0;
  color: #666;
  font-size: 1.2rem;
}

.field-title::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: rgb(254, 200, 39);
  background: linear-gradient(
    90deg,
    rgba(254, 200, 39, 1) 0%,
    transparent 100%
  );
  width: 50%;
  height: 1px;
}

.field-title::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  background: rgb(254, 200, 39);
  background: linear-gradient(
    270deg,
    rgba(254, 200, 39, 1) 0%,
    transparent 100%
  );
  width: 50%;
  height: 1px;
}

.field-subtitle {
  margin: 0.8rem 0 1rem 0;
  padding-left: 0.5rem;
  border-left: solid 3px #ccc;
  color: #ccc;
  font-size: 0.9rem;
  font-weight: normal;
}

.field-button {
  background-color: transparent;
  border: none;
  -webkit-border-radius: var(--borderRadius);
  -moz-border-radius: var(--borderRadius);
  border-radius: var(--borderRadius);
  padding: 0.5rem 1rem;
  color: var(--pttYellowColor);
}

.field-button > i {
  position: relative;
  top: 0rem;
  margin-left: 1rem;
}

/* #endregion */

/* #region Modal */
.modal {
  position: fixed;
  inset: var(--position);
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: var(--modalInOutAnimation);
  -moz-transition: var(--modalInOutAnimation);
  -o-transition: var(--modalInOutAnimation);
  transition: var(--modalInOutAnimation);
  overflow: auto;
  z-index: var(--zIndex);
}

.modal > .modal-content {
  position: relative;
  background-color: #fff;
  max-width: 30rem;
  margin: 3rem auto;
  -webkit-border-radius: var(--borderRadius);
  -moz-border-radius: var(--borderRadius);
  border-radius: var(--borderRadius);
  -webkit-box-shadow: var(--boxShadow);
  -moz-box-shadow: var(--boxShadow);
  -o-box-shadow: var(--boxShadow);
  box-shadow: var(--boxShadow);
  overflow: hidden;
}

.modal > .modal-content > .modal-header {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  align-items: center;
  background-color: transparent;
  padding: 1rem 1.5rem;
}

.modal > .modal-content > .modal-header > h1 {
  color: var(--pttLinkColor);
  font-size: 1.2rem;
}

.modal > .modal-content > .modal-header > .close {
  margin-left: auto;
}

.modal > .modal-content > .modal-header > .close > i {
  position: relative;
  top: 0rem;
  background-color: var(--dangerBgColor);
  width: 2rem;
  height: 2rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: var(--fontWeight);
  text-align: center;
  line-height: 2rem;
  cursor: pointer;
}

.modal > .modal-content > .modal-body {
  padding: 1rem 2rem;
}

.modal > .modal-content > .modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  border-top: solid 1px #f1f1f1;
}

.modal > .modal-content > .modal-footer > :not(:last-child) {
  margin: 0 0.5rem 0 0;
}
/* #endregion */

/* PC View */
@media screen and (min-width: 1280px) {
  /* #region Colums */
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }

  /* #endregion */
}

/* Tablet Horizontal  View */
@media screen and (max-width: 1280px) and (min-width: 800px) {
  /* #region General */
  html {
    font-size: 70%;
  }

  /* #endregion */

  /* #region Colums */
  .col-t-1 {
    width: 8.33%;
  }

  .col-t-2 {
    width: 16.66%;
  }

  .col-t-3 {
    width: 25%;
  }

  .col-t-4 {
    width: 33.33%;
  }

  .col-t-5 {
    width: 41.66%;
  }

  .col-t-6 {
    width: 50%;
  }

  .col-t-7 {
    width: 58.33%;
  }

  .col-t-8 {
    width: 66.66%;
  }

  .col-t-9 {
    width: 75%;
  }

  .col-t-10 {
    width: 83.33%;
  }

  .col-t-11 {
    width: 91.66%;
  }

  .col-t-12 {
    width: 100%;
  }

  /* #endregion */

  /* #region Buttons */
  .btn-group > button {
    font-size: 0.9rem !important;
  }
  /* #endregion */

  /* #region Modal */
  .modal > .modal-content {
    max-width: 500px;
  }

  /* #endregion */
}

/* Tablet Vertical View */
@media screen and (max-width: 800px) and (min-width: 480px) {
  /* #region General */
  html {
    font-size: 70%;
  }

  /* #endregion */

  /* #region Colums */
  .col-tv-1 {
    width: 8.33%;
  }

  .col-tv-2 {
    width: 16.66%;
  }

  .col-tv-3 {
    width: 25%;
  }

  .col-tv-4 {
    width: 33.33%;
  }

  .col-tv-5 {
    width: 41.66%;
  }

  .col-tv-6 {
    width: 50%;
  }

  .col-tv-7 {
    width: 58.33%;
  }

  .col-tv-8 {
    width: 66.66%;
  }

  .col-tv-9 {
    width: 75%;
  }

  .col-tv-10 {
    width: 83.33%;
  }

  .col-t-11 {
    width: 91.66%;
  }

  .col-tv-12 {
    width: 100%;
  }

  /* #endregion */

  /* #region Buttons */
  .btn-group > button {
    font-size: 0.9rem !important;
  }
  /* #endregion */

  /* #region Modal */
  .modal > .modal-content {
    max-width: 500px;
  }

  /* #endregion */
}

/* Mobile View */
@media screen and (max-width: 480px) {
  /* #region General */
  html {
    font-size: 55%;
  }

  /* #endregion */

  /* #region Colums */
  [class*="col-"] {
    width: 100%;
  }

  /* #endregion */

  /* #region Buttons */
  [class*="btn-"] {
    width: 100%;
  }

  /* #endregion */

  /* #region Modal */
  .modal > .modal-content {
    max-width: var(--containerWidth);
  }

  .modal > .modal-content > .modal-header > h1 {
    color: var(--pttLinkColor);
    font-size: 1.2rem !important;
  }

  .modal > .modal-content > .modal-footer > :not(:last-child) {
    margin: 0 0 0.5rem 0;
  }

  /* #endregion */

 
}
