.searchContainer {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 5;
}
.searchContainer > i {
  position: absolute;
  top: 5rem;
  right: 3rem;
  color: var(--dangerBgColor);
  font-size: 2.5rem !important;
  cursor: pointer;
}

.searchContainer > .searchField {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  height: 100%;
  margin: 0 auto;
}

.searchContainer > .searchField > .input {
  position: relative;
  width: 100%;
  margin: 100px 0 50px 0;
}
.searchContainer > .searchField > .input > i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: #ccc;
  font-size: 1.5rem;
}
.searchContainer > .searchField > .input > input {
  width: 100%;
  padding: 1rem 1rem 1rem 3.5rem;
  border: none;
  border-radius: 100rem;
  box-shadow: 0 0 0 0.4rem rgba(255, 255, 255, 0.2),
    0 0 0 1rem rgba(255, 255, 255, 0.1), 0 0 0 2rem rgba(255, 255, 255, 0.05);
}
.searchContainer > .searchField > .input > button {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--pttYellowColor);
  padding: 1rem 2rem;
  border: none;
  border-radius: 0 100rem 100rem 0;
  color: var(--pttLinkColor);
}

.searchContainer > .searchField > .searchBody {
  position: relative;
  background: rgb(255, 255, 255);
  width: 100%;
  max-height: 100%;
  border-radius: 1.5rem;
  direction: ltr;
  overflow-y: auto;
}
.searchContainer > .searchField > .searchBody > .searchCount {
  background-color: #ccc;
}

.searchContainer > .searchField > .searchBody > ul {
}
.searchContainer > .searchField > .searchBody > ul > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border-bottom: solid 1px #f1f1f1;
}
.searchContainer > .searchField > .searchBody > ul > li:last-child {
  border-bottom: none;
}
.searchContainer > .searchField > .searchBody > ul > li > span {
  font-size: 1.2rem;
}
.searchContainer > .searchField > .searchBody > ul > li > span > a {
  display: block;
  margin-right: 1rem;
  color: var(--pttLinkColor);
  font-size: 1rem;
  font-weight: normal;
}
.searchContainer > .searchField > .searchBody > ul > li > span > span {
  padding: 0.1rem 0.2rem;
  border-radius: 0.3rem;
  color: #fff;
  font-size: 0.7rem;
}
.searchContainer > .searchField > .searchBody > ul > li > span > span > i {
  margin: 0 0.5rem;
}
.searchContainer
  > .searchField
  > .searchBody
  > ul
  > li
  > span
  > span
  > i:last-child {
  display: none;
}
.searchContainer > .searchField > .searchBody > ul > li > a {
  display: block;
  color: #1ce782;
  font-size: 0.7rem;
}

.searchContainer > .searchField > .searchBody > .paging {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  margin-top: auto;
}
.searchContainer > .searchField > .searchBody > .paging > li {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 0.8rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  cursor: pointer;
  user-select: none;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 1);
}
.searchContainer > .searchField > .searchBody > .paging > .selectedPage {
  background-color: transparent;
  box-shadow: none;
  width: auto;
  cursor: none;
}

.isActive {
  background-color: #f1f1f1;
  cursor: not-allowed !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 199, 44, 1) !important;
}

.progress {
  animation: progressAnimation 1s ease-in-out infinite;
}

@keyframes progressAnimation {
  from {
    transform: translateY(-50%) rotate(360deg);
  }
  to {
    transform: translateY(-50%) rotate(0deg);
  }
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .searchContainer > i {
    top: 1rem;
    right: 1rem;
  }

  .searchContainer > .searchField > .input > button {
    display: block;
  }
}

/* Mobile */
@media only screen and (max-width: 790px) {
  .searchContainer > i {
    top: 1rem;
    right: 1rem;
  }

  .searchContainer > .searchField > .input > button {
    display: block;
  }

  .searchContainer > .searchField > .searchBody > .paging > .selectedPage {
    display: none;
  }
}
