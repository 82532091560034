.footer {
  position: relative;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  border-top: solid 5px var(--pttYellowColor);
  z-index: 1;
}

/* #region Start About */
.footer .about {
  position: relative;
  background-color: #f1f1f1;
  padding: 2rem;
  height: 100%;
}

.footer .about > .logoLeft {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.footer .about > .logoRight {
  position: absolute;
  left: 100%;
  bottom: 0;
  width: 350px;
  z-index: -1;
}

.footer .about > .description {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--pttLinkColor);
}

.footer .about > .description > img {
  width: 9rem;
  height: 3rem;
  margin-bottom: 1rem;
}

.footer .about > .description > h1 {
  position: relative;
  font-size: 1.1rem;
  z-index: 1;
}

.footer .about > .description > p {
  position: relative;
  margin: 1rem 0 1.5rem 0;
  line-height: 1.5;
  z-index: 1;
}

.footer .about > .description > .information {
  position: relative;
  margin-bottom: 2rem;
  z-index: 1;
}

.footer .about > .description > .information > li {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
}

.footer .about > .description > .information > li > p > span {
  float: right;
}

.footer .about > .description > .information > li > a {
  color: var(--pttLinkColor);
}

.footer .about > .description > .information > li > i {
  background-color: var(--pttLinkColor);
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  border-radius: 0.8rem;
  color: var(--pttYellowColor);
  text-align: center;
  line-height: 2rem;
}

.footer .about > .description > .socialMedia > li {
  display: inline-block;
  margin-right: 1rem;
}

.footer .about > .description > .socialMedia > li:last-child {
  margin-right: 0;
}

.footer .about > .description > .socialMedia > li > a {
  position: relative;
  top: 0;
  background-color: var(--pttLinkColor);
  width: 2.5rem;
  height: 2.5rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  color: #fff;
  text-align: center;
  line-height: 2.7rem;
  -webkit-transition: top 0.2s, background 0.2s, color 0.2s,
    box-shadow 0.2s ease-in-out;
  -moz-transition: top 0.2s, background 0.2s, color 0.2s,
    box-shadow 0.2s ease-in-out;
  -o-transition: top 0.2s, background 0.2s, color 0.2s,
    box-shadow 0.2s ease-in-out;
  transition: top 0.2s, background 0.2s, color 0.2s, box-shadow 0.2s ease-in-out;
  z-index: 1;
}

.footer .about > .description > .socialMedia > li > a:hover {
  top: -0.2rem;
  background-color: var(--pttYellowColor);
  color: var(--pttLinkColor);
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 30%);
}

.footer .about > .description > .socialMedia > li > a > i {
  font-size: 1.1rem;
}

/* #endregion */
/* End About */

/* #region Start UsefulLinks */
.footer .usefulLinks {
  position: relative;
  padding: 2rem;
}

.footer .usefulLinks > h1 {
  position: relative;
  padding: 0 0 0.5rem 0;
  margin-bottom: 1rem;
  color: var(--pttLinkColor);
  font-size: 1.1rem;
}

.footer .usefulLinks > h1::before {
  position: absolute;
  top: 100%;
  content: "";
  background-color: var(--pttYellowColor);
  width: 2rem;
  height: 0.1rem;
}

.footer .usefulLinks > ul > li:last-child {
  border-bottom: none;
}

.footer .usefulLinks > ul > li > a {
  width: 100%;
  padding: 0.5rem 0;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-transition: color 0.2s, padding 0.2s ease-in-out;
  -moz-transition: color 0.2s, padding 0.2s ease-in-out;
  -o-transition: color 0.2s, padding 0.2s ease-in-out;
  transition: color 0.2s, padding 0.2s ease-in-out;
}

.footer .usefulLinks > ul > li > a:hover {
  padding-left: 0.5rem;
  color: var(--pttYellowColor);
}

/* #endregion */
/* End UsefulLinks */

/* #region Start Online Transactions */
.footer .onlineTransactions {
  padding: 2rem;
}

.footer .onlineTransactions > h1 {
  position: relative;
  margin-bottom: 1.5rem;
  padding: 0 0 0.5rem 0;
  color: var(--pttLinkColor);
  font-size: 1.1rem;
}

.footer .onlineTransactions > h1::before {
  position: absolute;
  top: 100%;
  content: "";
  background-color: var(--pttGreenColor);
  width: 2rem;
  height: 0.1rem;
}

.footer .onlineTransactions > ul > li:last-child {
  border-bottom: none;
}

.footer .onlineTransactions > ul > li > a {
  width: 100%;
  padding: 0.5rem 0;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-transition: color 0.2s, padding 0.2s ease-in-out;
  -moz-transition: color 0.2s, padding 0.2s ease-in-out;
  -o-transition: color 0.2s, padding 0.2s ease-in-out;
  transition: color 0.2s, padding 0.2s ease-in-out;
}

.footer .onlineTransactions > ul > li > a:hover {
  padding-left: 0.5rem;
  color: var(--pttYellowColor);
}

/* #endregion */
/* End Online Transactions */

/* #region Start Subscription */
.footer .subscription {
  padding: 2rem;
}

.footer .subscription > h1 {
  position: relative;
  margin-bottom: 1.5rem;
  padding: 0 0 0.5rem 0;
  color: var(--pttLinkColor);
  font-size: 1.1rem;
}

.footer .subscription > h1::before {
  position: absolute;
  top: 100%;
  content: "";
  background-color: var(--pttYellowColor);
  width: 2rem;
  height: 0.1rem;
}

.footer .subscription > p {
  margin-bottom: 1rem;
  color: var(--pttLinkColor);
  line-height: 1.5;
}

.footer .subscription > .input > input {
  background-color: #eee;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border: none;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
}

.footer .subscription > .input > button {
  background-color: var(--pttYellowColor);
  width: 100%;
  padding: 1rem;
  border: none;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  box-shadow: 0 10px 15px -10px var(--pttYellowColor);
}

.footer .subscription > .input > button > i {
  -webkit-animation: progressAnimation 1s ease-in-out infinite;
  -moz-animation: progressAnimation 1s ease-in-out infinite;
  -o-animation: progressAnimation 1s ease-in-out infinite;
  animation: progressAnimation 1s ease-in-out infinite;
}

/* #endregion */
/* End Subscription */

.footer .spanMessage {
  color: darkgreen;
  font-size: small;
  font-weight: bold;
}
.cookieBanner {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  opacity: 90%;
  color: white;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  gap: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 60%;
}
.cookieBanner a {
  color: var(--pttYellowColor);
}
.cookieBanner p {
  width: 100%;
  text-align: justify;
}
.agreedButton {
  background-color: var(--pttYellowColor);
  color: #020202;
  border: none;
  padding: 8px 24px 12px 24px;
  border-radius: 4px;
  cursor: pointer;
}

.agreedButton:hover {
  background-color: #f2f2f2;
}
.agreedButton {
  border-radius: 1rem;
}

@-webkit-keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-moz-keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-o-keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes progressAnimation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .footer {
  }

  /* #region Start About */
  .footer .about {
    text-align: left !important;
  }

  .footer .about > .logoLeft {
    display: none !important;
  }

  .footer .about > .logoRight {
    display: none !important;
  }

  .footer .about > .description {
  }

  .footer .about > .description > img {
    width: 8rem !important;
  }

  .footer .about > .description > h1 {
    font-size: 1rem !important;
  }

  .footer .about > .description > p {
    font-size: 0.9rem !important;
  }

  .footer .about > .description > .information {
  }

  .footer .about > .description > .information > li {
  }

  .footer .about > .description > .information > li > p > span {
  }

  .footer .about > .description > .information > li > i {
  }

  .footer .about > .description > .socialMedia > li {
  }

  .footer .about > .description > .socialMedia > li:last-child {
  }

  .footer .about > .description > .socialMedia > li > a {
  }

  .footer .about > .description > .socialMedia > li > a:hover {
  }

  .footer .about > .description > .socialMedia > li > a > i {
    font-size: 1rem !important;
  }

  /* #endregion */
  /* End About */

  /* #region Start UsefulLinks */
  .footer .usefulLinks {
    text-align: left !important;
  }

  .footer .usefulLinks > h1 {
    font-size: 1rem !important;
  }

  .footer .usefulLinks > h1::before {
  }

  .footer .usefulLinks > ul > li:last-child {
  }

  .footer .usefulLinks > ul > li > a {
    font-size: 0.9rem !important;
  }

  .footer .usefulLinks > ul > li > a:hover {
  }

  /* #endregion */
  /* End UsefulLinks */

  /* #region Start Online Transactions */
  .footer .onlineTransactions {
    padding: 2rem 0 0 0 !important;
  }

  .footer .onlineTransactions > h1 {
    font-size: 1rem !important;
  }

  .footer .onlineTransactions > h1::before {
  }

  .footer .onlineTransactions > ul > li:last-child {
  }

  .footer .onlineTransactions > ul > li > a {
    font-size: 0.9rem !important;
  }

  .footer .onlineTransactions > ul > li > a:hover {
  }

  /* #endregion */
  /* End Online Transactions */

  /* #region Start Subscription */
  .footer .subscription {
    padding: 2rem !important;
  }

  .footer .subscription > h1 {
    font-size: 1rem !important;
  }

  .footer .subscription > h1::before {
  }

  .footer .subscription > p {
    font-size: 0.9rem !important;
  }

  .footer .subscription > .input > input {
    font-size: 0.9rem !important;
  }

  .footer .subscription > .input > button {
    font-size: 0.9rem !important;
  }

  /* #endregion */
  /* End Subscription */
}

/* Mobile */
@media only screen and (max-width: 790px) {
  .footer {
  }

  /* #region Start About */
  .footer .about {
    text-align: center !important;
  }

  .footer .about > .logoLeft {
    display: none;
  }

  .footer .about > .logoRight {
    display: none;
  }

  .footer .about > .description {
  }

  .footer .about > .description > img {
    width: 8rem !important;
  }

  .footer .about > .description > h1 {
    font-size: 1rem !important;
  }

  .footer .about > .description > p {
    font-size: 0.9rem !important;
  }

  .footer .about > .description > .information {
  }

  .footer .about > .description > .information > li {
    justify-content: center;
  }

  .footer .about > .description > .information > li > p > span {
  }

  .footer .about > .description > .information > li > i {
    display: none;
  }

  .footer .about > .description > .socialMedia > li {
  }

  .footer .about > .description > .socialMedia > li:last-child {
  }

  .footer .about > .description > .socialMedia > li > a {
  }

  .footer .about > .description > .socialMedia > li > a:hover {
  }

  .footer .about > .description > .socialMedia > li > a > i {
    font-size: 1rem !important;
  }

  /* #endregion */
  /* End About */

  /* #region Start UsefulLinks */
  .footer .usefulLinks {
    padding: 2rem 0;
    text-align: center;
  }

  .footer .usefulLinks > h1 {
    font-size: 1.2rem !important;
  }

  .footer .usefulLinks > h1::before {
  }

  .footer .usefulLinks > ul > li:last-child {
  }

  .footer .usefulLinks > ul > li > a {
    font-size: 0.9rem !important;
  }

  .footer .usefulLinks > ul > li > a:hover {
  }

  /* #endregion */
  /* End UsefulLinks */

  /* #region Start Online Transactions */
  .footer .onlineTransactions {
    text-align: center;
  }

  .footer .onlineTransactions > h1 {
    font-size: 1.2rem !important;
  }

  .footer .onlineTransactions > h1::before {
  }

  .footer .onlineTransactions > ul > li:last-child {
  }

  .footer .onlineTransactions > ul > li > a {
    font-size: 0.9rem !important;
  }

  .footer .onlineTransactions > ul > li > a:hover {
  }

  /* #endregion */
  /* End Online Transactions */

  /* #region Start Subscription */
  .footer .subscription {
    padding: 2rem !important;
    text-align: center;
  }

  .footer .subscription > h1 {
    font-size: 1.2rem !important;
  }

  .footer .subscription > h1::before {
  }

  .footer .subscription > p {
    font-size: 0.9rem !important;
  }

  .footer .subscription > .input > input {
    font-size: 0.9rem !important;
  }

  .footer .subscription > .input > button {
    font-size: 0.9rem !important;
  }
  .cookieBanner {
    width: 100%;
    flex-wrap: wrap;
    bottom: 4rem;
    justify-content: flex-start;
    align-items: center;
  }
  .cookieBanner p {
    width: 100%;
  }
  .agreedButton {
    width: 50%;
    margin-bottom: 0.3rem;
  }

  /* #endregion */
  /* End Subscription */
}
