section > .customServicesMenu {
  position: fixed;
  top: 9rem;
  right: 0;
  background-color: #fff;
  -webkit-border-radius: 0 0 0 1rem;
  -moz-border-radius: 0 0 0 1rem;
  -o-border-radius: 0 0 0 1rem;
  border-radius: 0 0 0 1rem;
  text-align: center;
  -webkit-box-shadow: 0 5px 15px 5px rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 15px 5px rgb(0, 0, 0, 0.2);
  -o-box-shadow: 0 5px 15px 5px rgb(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px 5px rgb(0, 0, 0, 0.2);
  z-index: 2;
  -webkit-transition: top 0.3s, right 0.3s, border 0.3s ease-in-out;
  -moz-transition: top 0.3s, right 0.3s, border 0.3s ease-in-out;
  -o-transition: top 0.3s, right 0.3s, border 0.3s ease-in-out;
  transition: top 0.3s, right 0.3s, border 0.3s ease-in-out;
}

section > .customServicesMenu > li:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 100%;
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  background-color: var(--pttYellowColor);
  width: 2.5rem;
  height: 2.5rem;
  border-bottom: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 20px 5px rgb(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px 5px rgb(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 20px 5px rgb(0, 0, 0, 0.2);
  box-shadow: 0 0 20px 5px rgb(0, 0, 0, 0.2);
}

section > .customServicesMenu > li:first-child > i {
  color: var(--pttLinkColor);
  font-size: 1.3rem;
}
section > .customServicesMenu > li {
  padding: 1rem;
  border-bottom: solid 1px #d8d8da;
  cursor: pointer;
}
section > .customServicesMenu > li:last-child {
  border-bottom: none;
}
section > .customServicesMenu > li > a {
  color: #0a0a0a;
  font-weight: var(--fontWeight);
}
section > .customServicesMenu > li:hover {
  background-color: var(--pttYellowColor);
}
section > .customServicesMenu > li:last-child:hover {
  border-bottom-left-radius: 1rem;
}
section > .customServicesMenu > li > a > i {
  display: block;
  color: var(--pttLinkColor);
  margin-bottom: 0.8rem;
  font-size: 2rem;
  opacity: 0.8;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: margin 0.3s, opacity 0.3s, font 0.3s ease-in-out;
  -moz-transition: margin 0.3s, opacity 0.3s, font 0.3s ease-in-out;
  -o-transition: margin 0.3s, opacity 0.3s, font 0.3s ease-in-out;
  transition: margin 0.3s, opacity 0.3s, font 0.3s ease-in-out;
}
section > .customServicesMenu > li > a > span {
  visibility: visible;
  opacity: 1;
  -webkit-transition: visibility 0.3s, opacity 0.3s ease-in-out;
  -moz-transition: visibility 0.3s, opacity 0.3s ease-in-out;
  -o-transition: visibility 0.3s, opacity 0.3s ease-in-out;
  transition: visibility 0.3s, opacity 0.3s ease-in-out;
}

section > .isVisibleCustomServicesMenu {
  top: 9rem;
  right: 0;
  -webkit-border-radius: 0 0 0 1rem;
  -moz-border-radius: 0 0 0 1rem;
  -o-border-radius: 0 0 0 1rem;
  border-radius: 0 0 0 1rem;
}

section > .customServicesMenu > li.wh {
  padding: 0 !important;
  width: 1.7rem !important;
  height: 1.7rem !important;
}
section > .customServicesMenu > li.wh > i {
  font-size: 1rem;
}
section > .customServicesMenu > li.isVisible > i {
  font-size: 1rem;
}
section > .customServicesMenu > li.isVisible > a > i {
  margin: 0;
  font-size: 1.2rem !important;
  opacity: 1 !important;
  color: var(--pttLinkColor);
}
section > .customServicesMenu > li.isVisible > a > span {
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden !important;
  opacity: 0 !important;
}

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  section > .customServicesMenu {
  }

  section > .customServicesMenu > li:first-child {
  }
  section > .customServicesMenu > li:first-child > i {
    font-size: 1.2rem !important;
  }
  section > .customServicesMenu > li {
  }
  section > .customServicesMenu > li:hover a > i {
  }
  section > .customServicesMenu > li:last-child {
  }
  section > .customServicesMenu > li > a {
  }
  section > .customServicesMenu > li > a > i {
    font-size: 1.5rem !important;
  }
  section > .customServicesMenu > li > a > span {
    font-size: 0.8rem !important;
  }

  section > .isVisibleCustomServicesMenu {
    right: 0;
  }

  section > .customServicesMenu > li.wh {
  }
  section > .customServicesMenu > li.wh > i {
  }
  section > .customServicesMenu > li.isVisible > i {
  }
  section > .customServicesMenu > li.isVisible > a > i {
  }
  section > .customServicesMenu > li.isVisible > a > span {
  }
}

/* Mobile */
@media only screen and (max-width: 790px) {
  section > .customServicesMenu {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-color: var(--pttGreenColor);
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    border: none;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  section > .customServicesMenu > li:first-child {
    display: none;
  }
  section > .customServicesMenu > li > a > i {
    color: #fff;
    font-size: 1.3rem;
  }
  section > .customServicesMenu > li:first-child > i {
  }
  section > .customServicesMenu > li {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 4rem;
    border: none;
  }
  section > .customServicesMenu > li::after {
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    content: "";
    width: 1px;
    height: 100%;
  }
  section > .customServicesMenu > li:last-child:after {
    display: none;
  }
  section > .customServicesMenu > li:hover a > i {
    color: #fff;
  }
  section > .customServicesMenu > li:last-child {
  }
  section > .customServicesMenu > li > a {
  }
  section > .customServicesMenu > li > a > i {
    margin: 0;
    font-size: 1.5rem !important;
  }
  section > .customServicesMenu > li > a > span {
    display: none;
  }
  section > .customServicesMenu > li:hover {
    background-color: unset;
  }
  section > .isVisibleCustomServicesMenu {
  }

  section > .customServicesMenu > li.wh {
  }
  section > .customServicesMenu > li.wh > i {
  }
  section > .customServicesMenu > li.isVisible > i {
  }
  section > .customServicesMenu > li.isVisible > a > i {
  }
  section > .customServicesMenu > li.isVisible > a > span {
  }
}
