/* #region Start General */
.container {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container > .title {
  position: relative;
  display: inline-block;
  margin-bottom: 4rem;
  color: var(--pttGreenColor);
  font-size: 1.5rem;
  font-weight: var(--fontWeight);
}

.container > .title::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -1rem;
  background-color: var(--pttGreenColor);
  content: "";
  width: 0.4rem;
  height: 2rem;
}

.container > .description {
  margin-bottom: 6rem;
  color: var(--pttGreenColor);
  font-weight: var(--fontWeight);
  z-index: 2;
}

.container > .iconContainer {
  position: relative;
  padding: 1rem;
}

.container > .iconContainer > .face {
  position: relative;
  margin: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  color: var(--pttYellowColor);
  font-size: 8rem;
  z-index: 1;
  background-color: white;
}

.container > .iconContainer > i:not(.face) {
  color: #676767;
  font-size: 2.5rem;
}

.container > .iconContainer > i:nth-child(1) {
  position: absolute;
  top: 1rem;
  left: 0rem;
  -webkit-transform: rotate(-51deg);
  -moz-transform: rotate(-51deg);
  -o-transform: rotate(-51deg);
  transform: rotate(-51deg);
}

.container > .iconContainer > i:nth-child(2) {
  position: absolute;
  top: -2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.container > .iconContainer > i:nth-child(3) {
  position: absolute;
  top: 1rem;
  right: 0rem;
  -webkit-transform: rotate(51deg);
  -moz-transform: rotate(51deg);
  -o-transform: rotate(51deg);
  transform: rotate(51deg);
}

.container > .iconContainer > i:nth-child(4) {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
  left: -2rem;
}

.container > .iconContainer > i:nth-child(5) {
  position: absolute;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
  -moz-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
  right: -2rem;
}
/* #endregion */
/* End General */

/* Tablet -> Horizontal */
@media only screen and (max-width: 1280px) {
  /* #region Start General */
  .container {
  }

  .container > .title {
    font-size: 1.5rem !important;
  }

  .container > .title::before {
  }

  .container > .description {
  }

  .container > .iconContainer {
  }

  .container > .iconContainer > .face {
    font-size: 6rem !important;
  }

  .container > .iconContainer > i:not(.face) {
    font-size: 2.5rem !important;
  }

  .container > .iconContainer > i:nth-child(1) {
  }

  .container > .iconContainer > i:nth-child(2) {
  }

  .container > .iconContainer > i:nth-child(3) {
  }

  .container > .iconContainer > i:nth-child(4) {
  }

  .container > .iconContainer > i:nth-child(5) {
  }
  /* #endregion */
  /* End General */
}

/* Tablet -> Vertical */
@media only screen and (max-width: 800px) {
  /* #region Start General */
  .container {
  }

  .container > .title {
    font-size: 1.5rem !important;
  }

  .container > .title::before {
  }

  .container > .description {
  }

  .container > .iconContainer {
  }

  .container > .iconContainer > .face {
    font-size: 6rem !important;
  }

  .container > .iconContainer > i:not(.face) {
    font-size: 2.5rem !important;
  }

  .container > .iconContainer > i:nth-child(1) {
  }

  .container > .iconContainer > i:nth-child(2) {
  }

  .container > .iconContainer > i:nth-child(3) {
  }

  .container > .iconContainer > i:nth-child(4) {
  }

  .container > .iconContainer > i:nth-child(5) {
  }
  /* #endregion */
  /* End General */
}
