.progress {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.progress > span > img {
  -webkit-animation: progressAnimation 1.5s ease-in-out infinite none;
  -moz-animation: progressAnimation 1.5s ease-in-out infinite none;
  -o-animation: progressAnimation 1.5s ease-in-out infinite none;
  animation: progressAnimation 1.5s ease-in-out infinite none;
}

@-webkit-keyframes progressAnimation {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0);
  }
  75% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(0);
  }
}
@-moz-keyframes progressAnimation {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0);
  }
  75% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(0);
  }
}
@-o-keyframes progressAnimation {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0);
  }
  75% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(0);
  }
}
@keyframes progressAnimation {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0);
  }
  75% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(0);
  }
}
