.container {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 0;
}

.container > span {
  font-weight: var(--fontWeight);
  color: var(--pttLinkColor);
  margin-top: 1rem;
}

.container > .section {
  margin-top: 10px;
}

.container > .section > .title {
  margin-top: 1rem;
  margin-left: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--pttLinkColor);
  font-size: 1.2rem !important;
  font-weight: var(--fontWeight);
}

.container > .section > .socialContent {
  margin-top: 10px;
  text-align: center;
}

.container > .section > .socialContent > .socialContentTitle {
  text-align: center;
  color: #999;
  font-size: 16px;
}

.container > .section > .socialContent > .socialMedia > li {
  margin-top: 20px;
  display: inline-block;
  margin-right: 1rem;
}

.container > .section > .socialContent > .socialMedia > li:last-child {
  margin-right: 0;
}

.container > .section > .socialContent > .socialMedia > li > a {
  position: relative;
  top: 0;
  background-color: var(--pttLinkColor);
  width: 2.5rem;
  height: 2.5rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  color: #fff;
  text-align: center;
  line-height: 2.7rem;
  -webkit-transition: top 0.2s, background 0.2s, color 0.2s,
    box-shadow 0.2s ease-in-out;
  -moz-transition: top 0.2s, background 0.2s, color 0.2s,
    box-shadow 0.2s ease-in-out;
  -o-transition: top 0.2s, background 0.2s, color 0.2s,
    box-shadow 0.2s ease-in-out;
  transition: top 0.2s, background 0.2s, color 0.2s, box-shadow 0.2s ease-in-out;
  z-index: 1;
}

.container > .section > .socialContent > .socialMedia > li > a:hover {
  top: -0.2rem;
  background-color: var(--pttYellowColor);
  color: var(--pttLinkColor);
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 30%);
}

.container > .section > .socialContent > .socialMedia > li > a > i {
  font-size: 1.1rem;
}

.notfoundLine {
  border: 0;
  border-bottom: 1px dashed #ccc;
}

.logoNotFound {
  width: 12rem;
}

.logoNotFound img {
  width: 100% !important;
  object-fit: cover;
  object-position: center;
}

.logoNot {
  width: 12rem;
}
