/* #region Start List */
.list {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  flex-wrap: wrap;
}
.list > li {
  position: relative;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  background-color: #f1f1f1;
  width: 40%;
  height: 4rem;
  margin: 2.5%;
  padding: 0 0.5rem;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
}
.list > li > a {
  width: 100%;
  color: var(--pttLinkColor);
  text-align: center;
}
.list > li > a > i {
  position: absolute;
  top: 1rem;
  background-color: #f1f1f1;
  width: 2rem;
  height: 2rem;
  color: var(--pttLinkColor);
  text-align: center;
  line-height: 2rem;
  font-size: 1.2rem !important;
}
.list > li > a > span {
  font-weight: var(--fontWeight);
}
/* #endregion */
/* End List */

/* #region Start Odd And Even */
.list > li:nth-child(odd) {
  border-left: solid 5px var(--pttYellowColor);
}
.list > li:nth-child(odd) > a > i {
  left: -1.9rem;
  -webkit-border-radius: 50% 0 0 50%;
  -moz-border-radius: 50% 0 0 50%;
  -o-border-radius: 50% 0 0 50%;
  border-radius: 50% 0 0 50%;
}
.list > li:nth-child(even) {
  border-right: solid 5px var(--pttYellowColor);
}
.list > li:nth-child(even) > a > i {
  right: -1.9rem;
  -webkit-border-radius: 0 50% 50% 0;
  -moz-border-radius: 0 50% 50% 0;
  -o-border-radius: 0 50% 50% 0;
  border-radius: 0 50% 50% 0;
}
/* #endregion */
/* End Odd And Even */

/* Tablet -> Horizontal */
@media only screen and (max-width: 1280px) {
  /* #region Start List */
  .list {
  }
  .list > li {
  }
  .list > li > a {
  }
  .list > li > a > i {
  }
  .list > li > a > span {
  }
  /* #endregion */
  /* End List */

  /* #region Start Odd And Even */
  .list > li:nth-child(odd) {
  }
  .list > li:nth-child(odd) > a > i {
  }
  .list > li:nth-child(even) {
  }
  .list > li:nth-child(even) > a > i {
  }
  /* #endregion */
  /* End Odd And Even */
}
