/* #region General */
.header {
  position: fixed;
  top: 3rem;
  right: 0;
  left: 0;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  align-items: center;
  background-color: #fff;
  height: 6rem;
  padding: 0 2rem;
  -webkit-box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 20px -15px rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.header > a > img {
  width: 9rem;
  margin-right: 3rem;
}
/* #endregion */
/* End General */

/* #region Start Mobil Menu */
.header > .mMenu {
  display: none;
}

.mOnlineTransactionAndInternetBanking {
  display: none;
}
/* #endregion */
/* End Mobil Menu */

/* #region Start Menu */
.header > .list {
  margin-left: 0.1rem;
}
.header > .list > .mItem {
  display: none;
}
.header > .list > li {
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem;
  -webkit-border-radius: 0 0 0.5rem 0;
  -moz-border-radius: 0 0 0.5rem 0;
  -o-border-radius: 0 0 0.5rem 0;
  border-radius: 0 0 0.5rem 0;
}
.header > .list > li:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  border-radius: 1rem;
  opacity: 0.5;
  width: 0%;
  background-color: var(--pttYellowColor);
  transform: translateX(-50%);
  transition: all 0.4s ease;
}
.header > .list > li:hover:before {
  width: 70%;
}

.header > .list > li:last-child {
  border-right: none;
}
.header > .list > li > span {
  color: var(--pttLinkColor);
  font-size: 1.1rem;
  font-weight: var(--fontWeight);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.header > .list > li > a {
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.header > .list > li > a > span {
  font-size: 1.1rem;
}
.header > .list > li > a > i {
  margin-right: 0.8rem;
  color: var(--pttYellowColor);
}

.header > .list > li:hover > ul {
  visibility: visible;
  opacity: 1;
}
.header > .list > li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 4rem;
  left: 1rem;
  background-color: #fff;
  border-radius: 1rem;
  width: 200px;
  -webkit-box-shadow: 0 0 30px -10px rgb(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 30px -10px rgb(0, 0, 0, 0.8);
  -o-box-shadow: 0 0 30px -10px rgb(0, 0, 0, 0.8);
  box-shadow: 0 0 30px -10px rgb(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}
.header > .list > li > ul::before {
  position: absolute;
  left: 1rem;
  bottom: 100%;
  content: "";
  border: solid 0.5rem transparent;
  border-bottom: solid 0.5rem #fff;
}
.header > .list > li > ul::after {
  position: absolute;
  bottom: 100%;
  background-color: transparent;
  content: "";
  width: 100%;
  height: 1rem;
}
.header > .list > li ul li {
  position: relative;
  border-bottom: solid 1px #f1f1f1;
}
.header > .list > li ul li:first-child {
  -webkit-border-radius: 1rem 1rem 0 0;
  -moz-border-radius: 1rem 1rem 0 0;
  -o-border-radius: 1rem 1rem 0 0;
  border-radius: 1rem 1rem 0 0;
}
.header > .list > li ul li:last-child {
  border-bottom: none;
  -webkit-border-radius: 0 0 1rem 1rem;
  -moz-border-radius: 0 0 1rem 1rem;
  -o-border-radius: 0 0 1rem 1rem;
  border-radius: 0 0 1rem 1rem;
}
.header > .list > li ul li:hover {
  background-color: #f1f1f1;
}
.header > .list > li ul li:last-child {
  padding-bottom: 0.8rem;
}
.header > .list > li ul li a {
  width: 100%;
  padding: 0.8rem 1rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.header > .list > li ul li a > i {
  position: absolute;
  right: 1rem;
  font-size: 1rem;
  color: var(--pttLinkColor);
  text-align: center;
  line-height: 1rem;
}
.header > .list > li > ul > li ul {
  position: absolute;
  top: 0;
  left: 85%;
  background-color: #fff;
}
.header > .list > li > ul li:hover > ul {
  visibility: visible;
  opacity: 1;
  left: 97%;
}

.logos {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
}
.centuryLogo {
  width: 200px;
}

/* #endregion */
/* End Menu */

/* #region Start Mega Menu */
.menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
}
.menu > h1 {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 2rem;
  border-bottom: solid 2px #f1f1f1;
  color: var(--pttLinkColor);
  font-size: 1.1rem;
  z-index: 3;
}
.menu > .closeButton {
  /* display: none; */
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 4;
}
.menu > .closeButton > i {
  color: var(--pttLinkColor);
  font-size: 1.2rem;
  cursor: pointer;
}
.menu > ul {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(8px);
  -moz-backdrop-filter: blur(8px);
  -o-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
  padding: 7rem 2rem 2rem 2rem;
  -webkit-transition: visibility 0.2s, opacity 0.2s, top 0.4s ease-in-out;
  -moz-transition: visibility 0.2s, opacity 0.2s, top 0.4s ease-in-out;
  -o-transition: visibility 0.2s, opacity 0.2s, top 0.4s ease-in-out;
  transition: visibility 0.2s, opacity 0.2s, top 0.4s ease-in-out;
  -webkit-box-shadow: 0px 10px 10px 0px rgb(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 10px 10px 0px rgb(0, 0, 0, 0.3);
  -o-box-shadow: 0px 10px 10px 0px rgb(0, 0, 0, 0.3);
  box-shadow: 0px 10px 10px 0px rgb(0, 0, 0, 0.3);
  overflow-y: auto;
  z-index: 1;
}
.menu > ul > li {
  width: 20%;
}
.menu > ul > li > h1 {
  color: var(--pttLinkColor);
}
.menu > ul > li > h1 > i {
  position: relative;
  background-color: #fff;
  width: 3rem;
  height: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: var(--ptt);
  font-size: 1.2rem;
  text-align: center;
  line-height: 3rem;
  -webkit-box-shadow: 0 0 0 3.5px rgb(254, 200, 39, 1),
    0 0 0 10px rgb(254, 200, 39, 0.3);
  -moz-box-shadow: 0 0 0 3.5px rgb(254, 200, 39, 1),
    0 0 0 10px rgb(254, 200, 39, 0.3);
  -ms-box-shadow: 0 0 0 3.5px rgb(254, 200, 39, 1),
    0 0 0 10px rgb(254, 200, 39, 0.3);
  box-shadow: 0 0 0 3.5px rgb(254, 200, 39, 1),
    0 0 0 10px rgb(254, 200, 39, 0.3);
}
.menu > ul > li > h1 > i::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -1.5rem;
  content: "";
  border-top: solid 10px transparent;
  border-right: solid 10px transparent;
  border-left: solid 10px var(--pttYellowColor);
  border-bottom: solid 10px transparent;
}
.menu > ul > li > h1 > label {
  margin-left: 1.2rem;
  padding-left: 0.5rem;
  border-left: solid 3px #eee;
  font-size: 1rem;
}
.menu > ul > li > ul {
  position: relative;
  margin-left: 1.4rem;
  padding: 0 0 0 1rem;
  border-left: solid 3px var(--pttYellowColor);
}
.menu > ul > li > ul > li {
  position: relative;
}
.menu > ul > li > ul > li:hover {
  background: rgb(254, 200, 39);
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(254, 200, 39, 1) 100%
  );
}
.menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li:hover {
  background: rgb(254, 200, 39);
  background: linear-gradient(
    90deg,
    rgba(254, 200, 39, 1) 0%,
    transparent 100%
  );
}
.menu > ul > li > ul > li::before {
  position: absolute;
  top: 1rem;
  left: -1rem;
  content: "";
  background-color: var(--pttYellowColor);
  width: 0.5rem;
  height: 0.2rem;
  -webkit-border-radius: 0 1rem 1rem 0;
  -moz-border-radius: 0 1rem 1rem 0;
  -o-border-radius: 0 1rem 1rem 0;
  border-radius: 0 1rem 1rem 0;
}
.menu > ul > li > ul > li > span {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  color: var(--pttLinkColor);
  font-weight: 600;
  -webkit-transition: color 0.2s, padding 0.2s ease-in-out;
  -moz-transition: color 0.2s, padding 0.2s ease-in-out;
  -ms-transition: color 0.2s, padding 0.2s ease-in-out;
  -o-transition: color 0.2s, padding 0.2s ease-in-out;
  transition: color 0.2s, padding 0.2s ease-in-out;
  cursor: pointer;
}
.menu > ul > li > ul > li > span > i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  font-size: 1rem;
  color: var(--pttLinkColor);
  text-align: center;
  line-height: 1rem;
}
.menu > ul > li > ul li:hover > span i::before {
  content: "\f068";
}
.list > li > ul li:hover > a i::before {
  content: "\f068";
}

.menu > ul > li > ul > li > a {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  color: var(--pttLinkColor);
  font-weight: 600;
  -webkit-transition: color 0.2s, padding 0.2s ease-in-out;
  -moz-transition: color 0.2s, padding 0.2s ease-in-out;
  -ms-transition: color 0.2s, padding 0.2s ease-in-out;
  -o-transition: color 0.2s, padding 0.2s ease-in-out;
  transition: color 0.2s, padding 0.2s ease-in-out;
}
.menu > ul > li > ul > li > a > i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  font-size: 1rem;
  color: var(--pttLinkColor);
  text-align: center;
  line-height: 1rem;
}
.menu > ul > li > ul > li:hover > a i::before {
  content: "\f068";
}

.menu > ul > li > ul > li:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0;
  left: 100%;
}
.menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0;
  left: -16.2rem;
}
.menu > ul > li > ul > li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 2rem;
  left: 0;
  background-color: #fff;
  width: 260px;
  border-left: solid 3px var(--pttYellowColor);
  -webkit-border-radius: 0 1rem 1rem 0;
  -moz-border-radius: 0 1rem 1rem 0;
  -o-border-radius: 0 1rem 1rem 0;
  border-radius: 0 1rem 1rem 0;
  -webkit-box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.8);
  -o-box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.8);
  z-index: 5;
}
.menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li ul {
  border-left: none;
  border-right: solid 3px var(--pttYellowColor);
  -webkit-border-radius: 1rem 0 0 1rem;
  -moz-border-radius: 1rem 0 0 1rem;
  -o-border-radius: 1rem 0 0 1rem;
  border-radius: 1rem 0 0 1rem;
}
.menu > ul > li > ul li ul li {
  position: relative;
  border-bottom: solid 1px #f1f1f1;
  cursor: pointer;
}
.menu > ul > li > ul li ul li > span {
  display: block;
  position: relative;
  padding: 0.5rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  transition: color 0.2s ease-in-out;
}
.menu > ul > li > ul li ul li > span > i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  -webkit-border-radius: 0 0.5rem 0.5rem 0;
  -moz-border-radius: 0 0.5rem 0.5rem 0;
  -o-border-radius: 0 0.5rem 0.5rem 0;
  border-radius: 0 0.2rem 0.2rem 0;
  color: var(--pttLinkColor);
  text-align: center;
  line-height: 1rem;
}
.menu > ul > li > ul li ul li > a {
  display: block;
  padding: 0.5rem;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  transition: color 0.2s ease-in-out;
}
.menu > ul > li > ul li ul li:last-child {
  border-bottom: none;
}
.menu > ul > li > ul > li ul li:hover {
  background: rgb(254, 200, 39);
  background: linear-gradient(
    90deg,
    rgba(254, 200, 39, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li ul li:hover {
  background: rgb(254, 200, 39);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(254, 200, 39, 1) 100%
  );
}
.menu > ul > li > ul > li ul li:hover > ul {
  visibility: visible;
  opacity: 1;
  left: 100%;
}
.menu
  > ul
  > li:is(:nth-child(5n-1), :nth-child(5n))
  > ul
  > li
  ul
  li:hover
  > ul {
  visibility: visible;
  opacity: 1;
  left: -100%;
}
.menu > ul > li > ul li ul li ul {
  top: 0;
}
/* #endregion */
/* End Mega Menu */

/* #region Start Special */
.header > .special {
  margin-left: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.header > .special > li {
  display: inline-block;
  background-color: #eee;
  margin-right: 1rem;
  padding: 0.8rem;

  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  color: var(--pttLinkColor);
  font-size: 1.1rem;
  font-weight: var(--fontWeight);
  -webkit-box-shadow: 0 10px 15px -10px rgb(0, 0, 0, 0.3);
  -moz-box-shadow: 0 10px 15px -10px rgb(0, 0, 0, 0.3);
  -o-box-shadow: 0 10px 15px -10px rgb(0, 0, 0, 0.3);
  box-shadow: 0 10px 15px -10px rgb(0, 0, 0, 0.3);
  cursor: pointer;
}
.header > .special > li > span {
  font-size: 1.1rem;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.header > .special > li > span > a {
  font-size: 1.1rem;
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  color: var(--pttLinkColor);
}
.header > .special > li > span:first-child:hover {
  color: var(--pttYellowColor);
}
.header > .special > li > span:hover {
  color: var(--pttGreenColor);
}

.header > .special > li > span > a:hover {
  color: var(--pttGreenColor);
}

.header > .special > li:first-child > span {
  padding: 0 1rem;
  border-right: solid 3px #ccc;
}
.header > .special > li:first-child > span:last-child {
  border: none;
}
.header > .special > li:last-child {
  display: none;
}
.header > .special > .lang {
  width: 3.5rem;
  text-align: center;
}
.header > .special > .search {
  width: 3.5rem;
  text-align: center;
  margin-right: 0;
}
/* #endregion  */
/* Start Special */

/* #region Start Custom Special */
.header > .menu .open {
  visibility: visible !important;
  opacity: 1 !important;
  top: 100% !important;
}
/* #endregion */
/* End Custom Special */

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  /* #region Start General */
  .header {
  }

  .header > a > img {
  }

  /* #endregion */
  /* End General */

  /* #region Start Mobil Menu */
  .header > .mMenu {
    display: block;
  }
  .header > .list {
    margin-left: 1rem;
  }

  .header > .mMenu > .list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    max-height: 500px;
    border-top: solid 3px #f1f1f1;
    padding: 1rem 2rem 1.5rem 2rem;
    box-shadow: 0 10px 10px -5px rgb(0, 0, 0, 0.2);
    overflow-x: auto;
  }
  .mOnlineTransactionAndInternetBanking {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: var(--pttYellowColor);
    padding: 0.7rem 0;
    text-align: center;
    z-index: 3;
  }
  .mOnlineTransactionAndInternetBanking > span {
    color: var(--pttLinkColor) !important;
    font-weight: var(--fontWeight);
  }
  .mOnlineTransactionAndInternetBanking > span:first-child {
    padding-right: 0.5rem;
    border-right: solid 3px #efbe2c !important;
  }
  .mOnlineTransactionAndInternetBanking > span > a {
    color: var(--pttLinkColor) !important;
  }
  .mOnlineTransactionAndInternetBanking > span:last-child {
    padding-left: 0.5rem;
  }
  .header > .mMenu > .list > li {
    position: relative;
    margin-bottom: 1rem;
    text-align: right;
  }
  .header > .mMenu > .list > li::before {
    display: none;
  }
  .header > .mMenu > .list > li:last-child {
    margin-bottom: 0;
  }
  .header > .mMenu > .list > li > span {
    color: var(--pttLinkColor);
    font-weight: var(--fontWeight);
  }
  .header > .mMenu > .list > li > a {
  }
  .header > .mMenu > .list > li > a > span {
    color: var(--pttLinkColor);
    font-weight: var(--fontWeight);
  }

  .header > .mMenu > .list > li ul {
    max-height: 0px;
    margin-right: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s ease-in-out;
    -moz-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
  }
  .header > .mMenu > .list > li:hover > ul {
    max-height: 2000px;
  }
  .header > .mMenu > .list > li ul li {
    position: relative;
    padding: 0.5rem 0 0.5rem 0;
  }
  .header > .mMenu > .list > li ul li::after {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 2px;
    background: rgb(241, 241, 241);
    background: linear-gradient(90deg, transparent 20%, #f1f1f1 100%);
    content: "";
  }
  .header > .mMenu > .list > li ul li:last-child:after {
    display: none;
  }
  .header > .mMenu > .list > li ul li:last-child {
    border-bottom: none;
  }
  .header > .mMenu > .list > li ul li a span {
    padding: 0 1.5rem;
    color: var(--pttLinkColor);
    font-size: 0.9rem !important;
  }
  .header > .mMenu > .list > li ul li a > i {
    position: absolute;
    top: 0.7rem;
    right: 0;
    color: var(--pttLinkColor);
  }
  .header > .mMenu > .list > li ul li ul {
    position: relative;
    background-color: #f1f1f1;
    max-height: 0;
    margin-right: 0.43rem;
    -webkit-border-radius: 1rem 0 1rem 1rem;
    -moz-border-radius: 1rem 0 1rem 1rem;
    -o-border-radius: 1rem 0 1rem 1rem;
    border-radius: 1rem 0 1rem 1rem;
    overflow-x: auto;
    -webkit-transition: max-height 0.3s ease-in-out;
    -moz-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
  }
  .header > .mMenu > .list > li ul li ul li {
    padding: 0.5rem 0;
  }
  .header > .mMenu > .list > li ul li ul li::after {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 1px;
    background: rgb(241, 241, 241);
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgb(201, 201, 201) 50%,
      transparent 100%
    );
    content: "";
  }
  .header > .mMenu > .list > li ul li ul li:last-child:after {
    display: none;
  }
  .header > .mMenu > .list > li ul li:hover > ul {
    max-height: 500px;
  }
  /* #endregion */
  /* End Mobil Menu */

  /* #region Start Menu */
  .header > .list {
    display: none;
  }
  /* #endregion */
  /* End Menu */

  /* #region Start Mega Menu */
  .menu {
    bottom: 0;
    min-width: auto;
    width: 100%;
    height: 100vh;
  }
  .menu > .closeButton {
    position: absolute;
    top: 1.8rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 0.8rem;
    font-weight: var(--fontWeight);
    text-align: center;
    line-height: 2rem;
    cursor: pointer;
  }
  .menu > .closeButton > i {
    color: var(--pttLinkColor);
    font-size: 1.5rem !important;
  }

  .menu > ul {
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .menu > ul > li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.8rem;
  }
  .menu > ul > li:hover > ul {
    max-height: 1000px;
  }
  .menu > ul > li > h1 {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .menu > ul > li > h1 > i {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .menu > ul > li > h1 > i::after {
  }
  .menu > ul > li > h1 > label {
  }
  .menu > ul > li > ul {
    margin-left: 1.2rem;
    max-height: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
  }
  .menu > ul > li > ul > li {
    padding-bottom: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .menu > ul > li > ul > li:hover {
    background: #f1f1f1;
    border-radius: 1rem;
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li:hover {
    background: #f1f1f1;
  }
  .menu > ul > li > ul > li::before {
  }
  .menu > ul > li > ul > li > span > i {
    top: 1rem;
    transform: translateY(0);
  }
  .menu > ul > li > ul > li > a {
    margin-top: 4px;
    font-weight: normal;
  }
  .menu > ul > li > ul > li > a:hover {
  }

  .menu > ul > li > ul > li:hover > ul {
    top: 0rem;
    left: 1rem;
    max-height: 1000px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li:hover > ul {
    left: 1rem;
    border-left: solid 3px var(--pttYellowColor);
    border-right: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  .menu > ul > li > ul > li ul {
    position: relative !important;
    top: 0rem;
    left: 1rem;
    width: 100%;
    max-height: 0;
    background-color: transparent !important;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s, opacity 1s ease-in-out;
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li ul {
    border-right: none;
    border-left: solid 3px #f1f1f1;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .menu > ul > li > ul li ul li {
    position: relative;
    background-color: transparent !important;
    padding-left: 1rem;
  }
  .menu > ul > li > ul li ul li::before {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "";
    background-color: var(--pttYellowColor);
    width: 0.5rem;
    height: 3px;
    -webkit-border-radius: 0 1rem 1rem 0;
    -moz-border-radius: 0 1rem 1rem 0;
    -o-border-radius: 0 1rem 1rem 0;
    border-radius: 0 1rem 1rem 0;
  }
  .menu > ul > li > ul li ul li > span {
    padding-left: 0;
  }
  .menu > ul > li > ul li ul li > span > i {
    right: 2rem;
  }
  .menu > ul > li > ul li ul li > a {
    padding-left: 0;
    font-weight: normal;
  }
  .menu > ul > li > ul li ul li:last-child {
    border-bottom: none;
  }
  .menu > ul > li > ul > li ul li:hover {
    background: rgb(254, 200, 39);
    background: linear-gradient(
      90deg,
      rgba(254, 200, 39, 1) 0%,
      transparent 100%
    );
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li ul li:hover {
    background: rgb(254, 200, 39);
    background: linear-gradient(
      90deg,
      rgba(254, 200, 39, 1) 0%,
      transparent 100%
    );
  }
  .menu > ul > li > ul > li ul li:hover > ul {
    left: 0;
    max-height: 1000px;
  }
  .menu
    > ul
    > li:is(:nth-child(5n-1), :nth-child(5n))
    > ul
    > li
    ul
    li:hover
    > ul {
    left: 0;
    max-height: 1000px;
  }
  .menu > ul > li > ul li ul li ul {
  }
  /* #endregion */
  /* End Mega Menu */

  /* #region Start Special */
  .header > .special {
  }
  .header > .special > li {
  }
  .header > .special > li > span {
    font-size: 1rem;
  }
  .header > .special > li:first-child {
    display: none;
  }
  .header > .special > li:first-child > span {
    padding: 0 0.5rem;
    border-right: solid 3px #ccc;
  }
  .header > .special > li:first-child > span:last-child {
    border: none;
  }
  .header > .special > li:last-child {
    background-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 -0.2rem 0 0;
    padding: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .header > .special > li:last-child > .icon {
    position: relative;
    top: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    text-align: center;
  }
  .header > .special > li:last-child > .icon > i {
    margin: 0;
    padding: 0;
    color: var(--pttLinkColor);
    font-size: 1.5rem !important;
  }
  .header > .special > .search {
    margin-right: 1rem;
  }
  /* #endregion */
  /* End Special */
}

/* Mobile */
@media only screen and (max-width: 790px) {
  /* #region Start General */
  .header > .special > li:last-child > .icon {
    margin-left: 1rem;
  }

  .header > .special > .lang {
    width: 3rem;
    height: 3rem;
    text-align: center;
    margin-right: 0.5rem;
  }
  .header > .special > .lang > span {
    display: flex;
    justify-content: center;
    transform: translateY(-15%);
  }
  .header > .special > .search {
    width: 3rem;
    height: 3rem;
    text-align: center;
    margin-right: 0.5rem;
  }
  .logos {
    display: flex;
    justify-content: center;
  }
  .centuryLogo {
    width: 120px;
    height: auto !important;
    margin-right: 0.5rem;
    margin-left: 0rem !important;
    margin-top: 0.1rem;
  }
  .logo {
    width: 120px;
    height: auto !important;
  }

  .header > a img {
    height: 35px !important;
  }

  /* #endregion */
  /* End General */

  /* #region Start Mobil Menu */
  .header > .mMenu {
    display: block;
  }
  .header > .mMenu > .list {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    max-height: 500px;
    border-top: solid 3px #f1f1f1;
    padding: 1rem 2rem 1.5rem 2rem;
    box-shadow: 0 10px 10px -5px rgb(0, 0, 0, 0.2);
    overflow-x: auto;
  }
  .mOnlineTransactionAndInternetBanking {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: var(--pttYellowColor);
    padding: 0.8rem 0;
    text-align: center;
    z-index: 3;
  }
  .mOnlineTransactionAndInternetBanking > span {
    color: var(--pttLinkColor) !important;
    font-weight: var(--fontWeight);
  }
  .mOnlineTransactionAndInternetBanking > span > a {
    color: var(--pttLinkColor) !important;
  }
  .mOnlineTransactionAndInternetBanking > span:first-child {
    padding-right: 0.5rem;
    color: var(--pttLinkColor) !important;
    border-right: solid 3px #efbe2c !important;
  }
  .mOnlineTransactionAndInternetBanking > span:last-child {
    padding-left: 0.5rem;
  }
  .header > .mMenu > .list > li {
    position: relative;
    margin-bottom: 1rem;
    text-align: right;
  }
  .header > .mMenu > .list > li::before {
    display: none;
  }
  .header > .mMenu > .list > li:last-child {
    margin-bottom: 0;
  }
  .header > .mMenu > .list > li > span {
    color: var(--pttLinkColor);
    font-weight: var(--fontWeight);
  }
  .header > .mMenu > .list > li > a {
  }
  .header > .mMenu > .list > li > a > span {
    color: var(--pttLinkColor);
    font-weight: var(--fontWeight);
  }

  .header > .mMenu > .list > li ul {
    max-height: 0px;
    margin-right: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -o-border-radius: 1rem;
    border-radius: 1rem;
    overflow-x: hidden;
    -webkit-transition: max-height 0.3s ease-in-out;
    -moz-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
  }
  .header > .mMenu > .list > li:hover > ul {
    max-height: 2000px;
  }
  .header > .mMenu > .list > li ul li {
    position: relative;
    padding: 0.5rem 0 0.5rem 0;
  }
  .header > .mMenu > .list > li ul li::after {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 2px;
    background: rgb(241, 241, 241);
    background: linear-gradient(90deg, transparent 20%, #f1f1f1 100%);
    content: "";
  }
  .header > .mMenu > .list > li ul li:last-child:after {
    display: none;
  }
  .header > .mMenu > .list > li ul li:last-child {
    border-bottom: none;
  }
  .header > .mMenu > .list > li ul li a span {
    padding: 0 1.5rem;
    color: var(--pttLinkColor);
    font-size: 0.9rem !important;
  }
  .header > .mMenu > .list > li ul li a > i {
    position: absolute;
    top: 0.7rem;
    right: 0;
    color: var(--pttLinkColor);
  }
  .header > .mMenu > .list > li ul li ul {
    position: relative;
    background-color: #f1f1f1;
    max-height: 0;
    margin-right: 0.43rem;
    -webkit-border-radius: 1rem 0 1rem 1rem;
    -moz-border-radius: 1rem 0 1rem 1rem;
    -o-border-radius: 1rem 0 1rem 1rem;
    border-radius: 1rem 0 1rem 1rem;
    overflow-x: auto;
    -webkit-transition: max-height 0.3s ease-in-out;
    -moz-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
  }
  .header > .mMenu > .list > li ul li ul li {
    padding: 0.5rem 0;
  }
  .header > .mMenu > .list > li ul li ul li::after {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 1px;
    background: rgb(241, 241, 241);
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgb(201, 201, 201) 50%,
      transparent 100%
    );
    content: "";
  }
  .header > .mMenu > .list > li ul li ul li:last-child:after {
    display: none;
  }
  .header > .mMenu > .list > li ul li:hover > ul {
    max-height: 500px;
  }

  /* #endregion */
  /* End Mobil Menu */

  /* #region Start Menu */
  .header > .list {
    display: none;
  }
  /* #endregion */
  /* End Menu */

  /* #region Start Mega Menu */
  .menu {
    bottom: 0;
    min-width: auto;
    width: 100%;
    height: 100vh;
  }
  .menu > .closeButton {
    position: absolute;
    top: 1.8rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 0.8rem;
    font-weight: var(--fontWeight);
    text-align: center;
    line-height: 2rem;
    cursor: pointer;
  }
  .menu > .closeButton > i {
    color: var(--pttLinkColor);
    font-size: 1.5rem !important;
  }

  .menu > ul {
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .menu > ul > li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.8rem;
  }
  .menu > ul > li:hover > ul {
    max-height: 1000px;
  }
  .menu > ul > li > h1 {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .menu > ul > li > h1 > i {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .menu > ul > li > h1 > i::after {
  }
  .menu > ul > li > h1 > label {
  }
  .menu > ul > li > ul {
    margin-left: 1.2rem;
    max-height: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
  }
  .menu > ul > li > ul > li {
    padding-bottom: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .menu > ul > li > ul > li:hover {
    background: #f1f1f1;
    border-radius: 1rem;
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li:hover {
    background: #f1f1f1;
  }
  .menu > ul > li > ul > li::before {
  }
  .menu > ul > li > ul > li > span > i {
    top: 1rem;
    transform: translateY(0);
  }
  .menu > ul > li > ul > li > a {
    margin-top: 4px;
    font-weight: normal;
  }
  .menu > ul > li > ul > li > a:hover {
  }
  .menu > ul > li > ul > li > a > i {
  }
  .menu > ul > li > ul > li:hover > a i::before {
    content: "\f068";
  }

  .menu > ul > li > ul > li:hover > ul {
    top: 0rem;
    left: 1rem;
    max-height: 1000px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li:hover > ul {
    left: 1rem;
    border-left: solid 3px var(--pttYellowColor);
    border-right: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
  .menu > ul > li > ul > li ul {
    position: relative !important;
    top: 0rem;
    left: 1rem;
    width: 100%;
    max-height: 0;
    background-color: transparent !important;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s, opacity 1s ease-in-out;
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li ul {
    border-right: none;
    border-left: solid 3px #f1f1f1;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .menu > ul > li > ul li ul li {
    position: relative;
    background-color: transparent !important;
    padding-left: 1rem;
  }
  .menu > ul > li > ul li ul li::before {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "";
    background-color: var(--pttYellowColor);
    width: 0.5rem;
    height: 3px;
    -webkit-border-radius: 0 1rem 1rem 0;
    -moz-border-radius: 0 1rem 1rem 0;
    -o-border-radius: 0 1rem 1rem 0;
    border-radius: 0 1rem 1rem 0;
  }
  .menu > ul > li > ul li ul li > span {
    padding-left: 0;
  }
  .menu > ul > li > ul li ul li > span > i {
    right: 2rem;
  }
  .menu > ul > li > ul li ul li > a {
    padding-left: 0;
    font-weight: normal;
  }
  .menu > ul > li > ul li ul li:last-child {
    border-bottom: none;
  }
  .menu > ul > li > ul > li ul li:hover {
    background: rgb(254, 200, 39);
    background: linear-gradient(
      90deg,
      rgba(254, 200, 39, 1) 0%,
      transparent 100%
    );
  }
  .menu > ul > li:is(:nth-child(5n-1), :nth-child(5n)) > ul > li ul li:hover {
    background: rgb(254, 200, 39);
    background: linear-gradient(
      90deg,
      rgba(254, 200, 39, 1) 0%,
      transparent 100%
    );
  }
  .menu > ul > li > ul > li ul li:hover > ul {
    left: 0;
    max-height: 1000px;
  }
  .menu
    > ul
    > li:is(:nth-child(5n-1), :nth-child(5n))
    > ul
    > li
    ul
    li:hover
    > ul {
    left: 0;
    max-height: 1000px;
  }
  .menu > ul > li > ul li ul li ul {
  }
  /* #endregion */
  /* End Mega Menu */

  /* #region Start Special */
  .header > .special {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
  }
  .header > .special > li {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .header > .special > li:first-child {
    display: none;
  }
  .header > .special > li > span {
    font-size: 1.2rem !important;
  }
  .header > .special > li:first-child > span {
  }
  .header > .special > li:first-child > span:last-child {
  }
  .header > .special > li:nth-child(2) {
  }
  .header > .special > li:last-child {
    display: block;
    background-color: transparent;
    margin-right: 0;
    padding: 0.8rem 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .header > .special > li:last-child > .icon {
  }
  .header > .special > li:last-child > .icon > i {
    font-size: 1.5rem !important;
  }
  .header > .special > .search {
    margin-right: 0.5rem;
  }

  /* #endregion */
  /* End Special */
}

@media only screen and (max-width: 374px) {
  .centuryLogo {
    display: none;
  }
}
