/* #region Start General */
.special {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
}
.special > ._special {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--pttYellowColor);
  height: 3rem;
  padding: 0 2rem;
}
/* End General */
/* #endregion */

/* #region Start Left */
.special > ._special > .left > li {
  display: inline-block;
  padding-right: 1rem;
}
.special > ._special > .left > li:last-child {
  border-right: none;
  padding-right: 0;
}
.special > ._special > .left > li > a {
  position: relative;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.special > ._special > .left > li > a:hover {
  color: #fff;
}
/* #endregion */
/* End Left */

/* #region Start Right */
.special > ._special > .right {
  margin-left: auto;
}
.special > ._special > .right > li {
  display: inline-block;
  padding-left: 1rem;
}
.special > ._special > .right > li > a {
  position: relative;
  color: var(--pttLinkColor);
  font-weight: var(--fontWeight);
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.special > ._special > .right > li > a:hover {
  color: #fff;
}
.special > ._special > .right li > a > i {
  margin-right: 0.6rem;
  padding-left: 1rem;
  border-left: solid 2px #efbe2c;
  color: var(--pttLinkColor);
  font-size: 0.7rem;
}
.special > ._special > .right li:first-child > a > i {
  border-left: none;
}
/* #endregion */
/* End Right */

/* #region Start Social */
.special > ._special > .social {
  margin-left: 1rem;
}
.special > ._special > .social > li {
  display: inline-block;
  padding-left: 1rem;
}
.special > ._special > .social > li > a {
  position: relative;
  top: 0;
  background-color: #efbe2c;
  width: 2rem;
  height: 2rem;
  border-radius: 0.7rem;
  color: var(--pttLinkColor);
  text-align: center;
  line-height: 2rem;
  -webkit-transition: background 0.2s, top 0.1s, box-shadow 0.2s ease-in-out;
  -moz-transition: background 0.2s, top 0.1s, box-shadow 0.2s ease-in-out;
  -o-transition: background 0.2s, top 0.1s, box-shadow 0.2s ease-in-out;
  transition: background 0.2s, top 0.1s, box-shadow 0.2s ease-in-out;
}
.special > ._special > .social > li > a:hover {
  top: -0.1rem;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
/* #endregion */
/* End Social */

/* Tablet */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
  /* #region Start General */
  .special {
  }
  .special > ._special {
  }
  /* End General */
  /* #endregion */

  /* #region Start Left */
  .special > ._special > .left > li {
    padding-right: 0.5rem;
  }

  .special > ._special > .left > li:last-child {
  }

  .special > ._special > .left > li > a {
    font-size: 0.8rem !important;
  }
  .special > ._special > .left > li > a:hover {
    color: #fff;
  }
  /* #endregion */
  /* End Left */

  /* #region Start Right */
  .special > ._special > .right {
  }
  .special > ._special > .right > li {
    padding-left: 0.5rem;
  }
  .special > ._special > .right > li > a {
  }
  .special > ._special > .right > li > a:hover {
    color: #fff;
  }
  .special > ._special > .right > li > a > span {
    font-size: 0.8rem !important;
  }
  .special > ._special > .right li > a > i {
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 0.65rem !important;
  }
  .special > ._special > .right li:first-child > a > i {
  }
  /* #endregion */
  /* End Right */

  /* #region Start Social */
  .special > ._special > .social {
    margin-left: 0.5rem;
  }
  .special > ._special > .social > li {
    padding-left: 0.5rem;
  }
  .special > ._special > .social > li > a {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .special > ._special > .social > li > a > i {
    font-size: 0.8rem !important;
  }
  .special > ._special > .social > li > a:hover {
  }
  /* #endregion */
  /* End Social */
}

/* Mobile */
@media only screen and (max-width: 790px) {
  /* #region Start General */
  .special {
    display: none;
  }
  .special > ._special {
  }
  /* End General */
  /* #endregion */

  /* #region Start Left */
  .special > ._special > .left > li {
  }
  .special > ._special > .left > li:last-child {
  }
  .special > ._special > .left > li > a {
  }
  .special > ._special > .left > li > a:hover {
  }
  /* #endregion */
  /* End Left */

  /* #region Start Right */
  .special > ._special > .right {
  }
  .special > ._special > .right > li {
  }
  .special > ._special > .right > li > a {
  }
  .special > ._special > .right > li > a:hover {
  }
  .special > ._special > .right > li > a > span {
  }
  .special > ._special > .right li > a > i {
  }
  .special > ._special > .right li:first-child > a > i {
  }
  /* #endregion */
  /* End Right */

  /* #region Start Social */
  .special > ._special > .social {
  }
  .special > ._special > .social > li {
  }
  .special > ._special > .social > li > a {
  }
  .special > ._special > .social > li > a > i {
  }
  .special > ._special > .social > li > a:hover {
  }
  /* #endregion */
  /* End Social */
}
